<template>
    <button @click="url ? $router.push(url):''"  :type="type ? 'submit' : ''" :class="classList+' text-white'" class="font-bold  px-7 py-2 uppercase">
       {{content}}
    </button>
</template>

<script>
    import renderMixin from "../mixins/renderMixin";

    export default {
        name: "Button",
        mixins: [renderMixin],
        props: {
            classList: String,
            type: String,
            content: String,
            url: String
        }
    }
</script>
