<template>
    <li class="leading-10"><router-link :to="link" @click.native="setActive(menuItem)" :class="{ active: isActive(menuItem) }">{{text}}</router-link></li>
</template>
<script>
    export default {
        name: "NavLink",
        data() {
            return {
                activeItem: 'home'
            }
        },
        props: {
            menuItem : String,
            text: String,
            link: String
        },
        methods: {
            isActive: function (item) {
                return this.activeItem === item
            },
            setActive: function (item) {
                this.activeItem = item
                this.$emit('setClose')
            }
        }
    }

</script>