<template>
    <main class="mb-auto" v-if="profile && page">
        <section class="background-purple p-8 flex items-center lg:items-stretch "  :style="{ height: height+'px'} ">
            <div class="lg:max-w-screen-xl mx-auto flex flex-col items-center lg:flex-row lg:justify-around flex-wrap lg:mx-auto">
                <img class="w-44 lg:w-1/4 2xl:w-1/3 3xl:w-5/12" :src="page.Images[0].url" :alt="page.Images[0].alt">
                <h1 class="3xl:text-5xl  lg:text-2xl xl:text-3xl 2xl:text-4xl text-white text-center text-3xl mt-8 lg:mt-0 lg:text-left lg:w-4/12" >{{profile['lead_'+$i18n.locale]}}</h1>
                <figure class="w-full flex justify-center pb-3 bounce cursor-pointer">
                    <img @click="scrollToElement" src="~@/assets/icons/down-arrow-double.svg" class="svg-beige hidden lg:block w-10 " alt="Flèches vers les bas">
                </figure>
            </div>

        </section>

        <section class="background-gold p-8 scroll-element">
            <div class="lg:max-w-screen-xl mx-auto flex flex-col flex-wrap items-center lg:flex-row">
                <div class="lg:w-1/2 flex flex-col">
                    <div>
                        <v-runtime-template class="text-white" :template="replaceLinks(profile['short_about_'+$i18n.locale])"></v-runtime-template>
                    </div>
                    <img class="w-24 self-end sm:w-32" :src="profile.signature.url" :alt="profile.signature.alt">
                </div>
                <div class="flex flex-col items-center lg:w-1/2">
                    <BorderImage class="w-9/12 mt-14" :img="profile.about_img.url" :is-full-height-img="false" :alt="profile.about_img.alt"/>
                    <Button url="/contact" class-list="mt-14 background-blue text-white" :content="page.Texts[4]['content_'+$i18n.locale]"/>
                    <SocialNetworks color-class="svg-purple" class="mt-14" :element="{'instagram_url':profile.instagram_url, 'linkedin_url':profile.linkedin_url}"/>
                </div>
            </div>
        </section>

        <section class="background-blue py-8 px-1" v-if="clients">
            <div class="lg:max-w-screen-xl mx-auto">
                <h2 class="text-white text-center text-2xl">
                    {{page.Texts[2]['content_'+$i18n.locale]}}
                </h2>
                <Slider :slideList="clients"/>
            </div>

        </section>
        <section class="background-beige py-8 px-3" v-if="partners">
            <div class="lg:max-w-screen-xl mx-auto ">
                <h2 class="text-purple text-center text-2xl md:w-full">
                    {{page.Texts[3]['content_'+$i18n.locale]}}
                </h2>
                <div class="flex flex-col items-center md:flex-row md:flex-wrap md:justify-around">
                    <div class="flex flex-col items-center md:w-1/2 lg:w-1/3" v-for="partner in partners" :key="partner.id">
                        <BorderImage :is-full-height-img="false" class="mt-14 md:h-48 w-48 md:w-auto" first-border="gold-border" :img="partner.logo.url" :alt="partner.logo.alt"/>
                        <SocialNetworks color-class="svg-purple" :element="partner" class="mt-14 "/>
                    </div>
                </div>

            </div>
        </section>
        <SurveyPropose/>
    </main>
</template>
<style>

</style>
<script>
    import BorderImage from "../components/BorderImage";
    import SocialNetworks from "../components/SocialNetworks";
    import Slider from "../components/BorderImageSlider";
    import Button from "../components/Button";
    import SurveyPropose from "../components/SurveyPropose";
    import axios from 'axios';
    import renderMixin from "../mixins/renderMixin";
    import VRuntimeTemplate from "v-runtime-template";

    export default {
        components: {SurveyPropose, Button, Slider, SocialNetworks, BorderImage, VRuntimeTemplate},
        data() {
            return {
                page:null,
                profile:null,
                clients:null,
                partners:null
            }
        },
        mixins: [ renderMixin],
        methods: {
            scrollToElement() {
                const el = this.$el.getElementsByClassName('scroll-element')[0];

                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({behavior: 'smooth'});
                }
            }
        },
        computed: {
            height () {
                return window.innerHeight-document.getElementsByTagName('header')[0].scrollHeight
            }
        },
        mounted() {

            axios
                .get(process.env.VUE_APP_API_URL+'/api/users/1')
                .then(response => (
                    this.profile = response.data,
                    axios
                        .get(process.env.VUE_APP_API_URL+'/api/clients')
                        .then(response => (
                            this.clients = response.data,
                                axios
                                    .get(process.env.VUE_APP_API_URL+'/api/partners')
                                    .then(response => (
                                        this.partners = response.data,
                                            axios
                                                .get(process.env.VUE_APP_API_URL+'/api/pages/1')
                                                .then(response => (
                                                    this.page = response.data,
                                                    this.$emit('removeLoader'),
                                                    this.sendDataToParent(this.page)
                                                ))
                                    ))
                        ))
                        ))
        }
    }

</script>