<template>
    <hooper :settings="hooperSettings" class="h-auto" id="portfolio-slider">
        <slide class="flex justify-center  h-full w-10/12 " v-for="(slide, index) in slideList" :key="index">
            <img class="md:pl-6 md:pr-6  object-cover w-auto" :src="slide.url" :alt="slide.alt">
        </slide>
        <hooper-navigation slot="hooper-addons" class="mt-5 md:mt-0 flex flex-row justify-between">
                <img slot="hooper-prev"  class="svg-gold w-4 " src="~@/assets/icons/left-arrow.svg" alt="">
                <img  slot="hooper-next" class="svg-gold  w-4" src="~@/assets/icons/right-arrow.svg" alt="">
        </hooper-navigation>
    </hooper>
</template>

<script>
    import {
        Hooper,
        Slide,
        Navigation as HooperNavigation
    } from 'hooper';
    import 'hooper/dist/hooper.css';

    export default {
        name: 'PortfolioSlider',
        components: {
            Hooper,
            Slide,
            HooperNavigation
        },
        props : {
            slideList : Array
        },
        data() {
            return {
                hooperSettings: {
                    itemsToShow: 1,
                    centerMode: true,
                    breakpoints: {
                        768: {
                            centerMode: false,
                            itemsToShow: 2
                        },
                    },
                    infiniteScroll: true,
                    wheelControl: false
                }
            };
        }
    };
</script>

<style lang="scss">
    #portfolio-slider{
        .hooper-next, .hooper-prev{
            position:initial;
            padding:0;
        }

        .hooper-track{
            align-items:center;
        }
        .hooper-list{
            ul{
                li{
                    img{
                        max-height:80vh
                    }
                }
            }
        }


    }

    @media screen and (min-width: 768px) {
        .hooper-next, .hooper-prev{
            position:absolute !important;
            padding:0;
            top:50%;
            transform: translateY(-50%);
        }
        .hooper-next{
            right:0;
        }
        .hooper-left{
            left:0;
        }
    }


</style>
