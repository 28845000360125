<template>
    <main class="mb-auto" v-if="page">
        <section class="background-purple p-8 ">
            <div class="lg:max-w-screen-xl lg:mx-auto flex flex-col items-center text-center"
            >
                <img class="w-44" :src="page.Images[0].url" :alt="page.Images[0].alt">
                <h1 class="text-white text-3xl mt-8 text-gold">{{page['title_'+$i18n.locale]}}</h1>
                <v-runtime-template class="mt-8 text-white" :template="replaceLinks(page.Texts[0]['content_'+$i18n.locale])"></v-runtime-template>
            </div>
        </section>
        <section class="background-gold p-8">
            <div class="lg:max-w-screen-lg lg:mx-auto flex flex-col lg:flex-row lg:flex-wrap lg:justify-between">
                <div class="lg:w-full lg:px-20 flex flex-col lg:flex-row lg:justify-between">
                    <div>
                        <h2 class="text-white font-medium">
                            <v-runtime-template :template="replaceLinks(page.Texts[13]['content_'+$i18n.locale])"></v-runtime-template>
                        </h2>
                        <ul v-if="infos" class="text-white">
                            <li>Email : <a :href="'mailto:'+infos.email">{{infos.email}}</a></li>
                            <li>Téléphone : <a href="tel:06 28 46 42 3">{{infos.phone}}</a></li>
                            <li class="mt-5"><address class="not-italic">
                                {{infos['address_'+$i18n.locale]}}
                            </address></li>
                        </ul>
                    </div>

                    <img class="w-44 mt-14 self-center lg:h-5/12 lg:mt-0" :src="page.Images[1].url" :alt="page.Images[1].alt">
                </div>

                <div class="lg:px-0 lg:w-7/12 lg:mx-auto">
                    <h2 class="text-blue text-lg mt-14 px-5 lg:w-full">
                        <v-runtime-template :template="replaceLinks(page.Texts[1]['content_'+$i18n.locale])"></v-runtime-template>
                    </h2>
                    <ContactForm :texts="page.Texts"/>
                </div>

            </div>
        </section>
        <section class="background-blue p-8">
            <div class="lg:max-w-screen-lg lg:mx-auto flex flex-col lg:flex-row lg:flex-wrap lg:justify-between">
                <div class="lg:px-0 lg:w-7/12 lg:mx-auto flex flex-col">
                    <h2 class="text-white text-lg px-5" >
                        <v-runtime-template  :template="replaceLinks(page.Texts[6]['content_'+$i18n.locale])"></v-runtime-template>
                    </h2>
                    <AskProjectForm :texts="page.Texts"/>
                </div>
            </div>

        </section>
        <SurveyPropose/>
    </main>
</template>

<style lang="scss" >
#project_form, #contact_form{
    input[type="text"] {
        border-bottom: 3px solid $blue;
    }
}

#project_form {
    input[type="text"] {
        border-color: $beige;
    }
}
</style>
<script>
    import SurveyPropose from "../components/SurveyPropose";
    import ContactForm from "../components/ContactForm";
    import AskProjectForm from "../components/AskProjectForm";
    import axios from "axios";
    import renderMixin from "../mixins/renderMixin";
    import VRuntimeTemplate from "v-runtime-template";

    export default {
        components: {
            AskProjectForm,
            ContactForm,
            SurveyPropose,
            VRuntimeTemplate
        },
        mixins: [ renderMixin],

        data() {
            return {
               page:null,
                infos:null
            }
        },
        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL+'/api/pages/4')
                .then(response => (
                        this.page = response.data,
                        this.sendDataToParent(this.page)
                    )
                )
            axios
                .get(process.env.VUE_APP_API_URL+'/api/users/1')
                .then(response => (
                        this.infos = response.data
                    )
                )
        }
    }
</script>