import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services'
import Contact from '../views/Contact'
import Portfolio from '../views/Portfolio'
import StepByStep from '../views/StepByStep'
import About from '../views/About'
import LegalNotice from '../views/LegalNotice'
import Tos from '../views/PrivacyPolicy'
import NotFound from '../views/404'
import Survey from '../views/Survey'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/mon-savoir-faire',
        name: 'services',
        component: Services
    },
    {
        path: '/votre-projet-pas-a-pas',
        name: 'step-by-step',
        component: StepByStep
    },
    {
        path: '/qui-suis-je',
        name: 'about',
        component: About
    },
    {
        path: '/mes-realisations',
        name: 'portfolio',
        component: Portfolio
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/mentions-legales',
        name: 'legalNotice',
        component: LegalNotice
    },
    {
        path: '/conditions-generales-de-vente',
        name: 'tos',
        component: Tos
    },
    {
        path: '/404',
        component: NotFound },
    {
        path: '*',
        redirect: '/404'
    },
    {
        path: '/questionnaire-cahier-des-charges',
        name: 'survey',
        component: Survey
    },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,

    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

export default router
