<template>
    <div class="flex flex-col">
        <div :id="'loadingBtn_'+id" :class="bg +' '+ this.customStyle" class="hidden  px-7 py-2 mx-auto mt-24 lg:mx-0">
            <div class="flex flex-row justify-center">
                <svg class="animate-spin h-5 w-5 mr-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span  class="text-white">
                    {{textLoading}}
                </span>
            </div>
        </div>
        <Button type="submit" :id="'submitBtn_'+id"  :class-list="classList" :content="textButton"/>
    </div>


</template>

<script>
    import Button from "./Button";
    export default {
        name: 'LoadingButton',
        props:{
            textLoading:String,
            textButton:String,
            customStyle:String,
            id:String,
            bg:String
        },
        data() {
            return {
                classList:"mt-14 mx-5 lg:mx-0"
            }
        },
        mounted() {
            this.classList=this.classList+' '+this.customStyle+' '+this.bg
        },
        components:{
            Button
        }
    }
</script>