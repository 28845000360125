/* eslint camelcase: "off" */
import { extend, setInteractionMode } from 'vee-validate';
import {
  required,
  email,
  numeric
} from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'This field is required ',
});

extend('email', {
  ...email,
  message: 'Enter an email address in the correct format ',
});


extend('url', {
  validate: (value) => {
    const regex = new RegExp(/^(http|ftp|https)?(:\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^!=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])+$/);
    return regex.test(value);
  },
  message: 'Please fill in a valid url ',
});

extend('numeric', {
  ...numeric ,
  message: 'You must enter a number'
});
