<template>
    <section>
        <ul class="flex flex-row " v-if="element">
                <a :href="element.website_url"  v-if="element.website_url" class="w-9 mr-3">
                    <img :class="colorClass" src="~@/assets/icons/world.svg" alt="Icone représentant le monde"/>
                </a>
                <a :href="element.instagram_url"  v-if="element.instagram_url" class="w-9 mr-3">
                    <img :class="colorClass" src="~@/assets/icons/instagram.svg" alt="Icone de Instagram"/>
                </a>
                <a :href="element.linkedin_url"  v-if="element.linkedin_url" class="w-9 mr-3">
                    <img :class="colorClass" src="~@/assets/icons/linkedin.svg" alt="Icone de Linkedin"/>
                </a>
                <a :href="element.facebook_url"  v-if="element.facebook_url" class="w-9 mr-3">
                    <img :class="colorClass" src="~@/assets/icons/facebook.svg" alt="Icone de Facebook"/>
                </a>
        </ul>
    </section>
</template>
<style scoped lang="scss">
</style>

<script>
    export default {
        name: "SocialNetworks",
        props:{
            colorClass: String,
            element:  Object,
        }
    }
</script>