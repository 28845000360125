<template>
    <footer class="background-purple" :class="this.$parent.isBlur === true ? 'blur' : ''">
        <div v-if="pages" class="xl:max-w-screen-xl xl:mx-auto flex flex-col py-4 xl:flex-row xl:px-20 xl:justify-between items-center text-center xl:text-left text-white flex-wrap">
            <figure class="w-1/6 xl:w-32 mb-4 xl:mb-0">
                <router-link to="/">
                    <img :src="logo.url" :alt ="logo.alt" />
                </router-link>
            </figure>
            <nav class="xl:w-auto flex flex-col xl:flex-row ">
                <ul class="xl:mr-10 xl:ml-5">
                    <NavLink link="/" :text="pages[0]['title_'+$i18n.locale]" menu-item="home"/>
                    <NavLink link="/mon-savoir-faire"  :text="pages[1]['title_'+$i18n.locale]" menu-item="services"/>
                    <NavLink link="/votre-projet-pas-a-pas" :text="pages[4]['title_'+$i18n.locale]" menu-item="step-by-step"/>
                </ul>
                <ul>
                    <NavLink link="/qui-suis-je" :text="pages[2]['title_'+$i18n.locale]" menu-item="about"></NavLink>
                    <NavLink link="/mes-realisations" :text="pages[5]['title_'+$i18n.locale]" menu-item="portfolio"></NavLink>
                    <NavLink link="/contact" :text="pages[3]['title_'+$i18n.locale]" menu-item="contact"></NavLink>
                </ul>
            </nav>
            <section id="legals_pages" class="xl:w-5/12 mt-14 xl:mt-0 flex flex-col  xl:flex-row  text-center justify-center">
                <router-link class="underline" to="/mentions-legales">{{pages[6]['title_'+$i18n.locale]}}</router-link>
                <p class="hidden xl:block px-2">-</p>
                <router-link class="underline" to="/conditions-generales-de-vente">{{pages[7]['title_'+$i18n.locale]}}</router-link>
            </section>
            <SocialNetworks v-if="profile" :element="{'instagram_url':profile.instagram_url, 'linkedin_url':profile.linkedin_url}" color-class="svg-gold" class="mt-14 xl:mt-0"/>

            <p class="mt-14 w-screen">Copyright © {{ new Date().getFullYear() }} - Andréa Carenso</p>
        </div>

    </footer>
</template>
<style lang="scss" scoped>
</style>
<script>
    import NavLink from "../../components/NavLink";
    import SocialNetworks from "../../components/SocialNetworks";
    import axios from "axios";
    export default {
        name: "Footer",
        props:{
            pages:Array,
            logo: Object
        },
        data() {
            return {
                profile:null,
            }
        },
        components: {SocialNetworks, NavLink},
        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL+'/api/users/1')
                .then(response => (
                    this.profile = response.data
                ))
        }
    }
</script>