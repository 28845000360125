<template>
  <div id="app" class="flex flex-col h-screen" >
    <div class="spinner" v-if="loading">
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
    <Header  :pages="this.pages" :logo="logo" v-if="logo && pages " v-on:changeMetas="changeMetas"/>
    <router-view  v-on:setBlur="setBlur" v-on:setTitle="setTitle" v-on:removeLoader="removeLoader" />
    <Footer :pages="this.pages" :logo="logo" v-if="logo && pages "/>
  </div>
</template>

<script>

import Header from "./views/parts/_header";
import Footer from "./views/parts/_footer";
import axios from "axios";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'App',
  components: {Footer, Header, PulseLoader},
  data (){
    return {
      isBlur: false,
      pages:null,
      logo:null,
      baseTitle:"AC Graphiste",
      loading: true,
      title:null
    }
  },
  mounted() {
    axios
            .get(process.env.VUE_APP_API_URL + '/api/pages')
            .then(response => (
                            this.pages = response.data
                    )
            )
    axios
            .get(process.env.VUE_APP_API_URL + '/api/images/40')
            .then(response => (
                            this.logo = response.data
                    )
            )
    document.title = this.baseTitle
  },
  watch:{
    $route (){
      this.loading = true,
      document.title = this.baseTitle
    }
  },
  methods : {
    setBlur (value){
      this.isBlur = value
    },
    setTitle(title){
      this.title=title
      document.title=title[this.$i18n.locale]+' | '+this.baseTitle
    },
    removeLoader(){
      setTimeout(() =>
          this.loading = false,
          500
      );
    },
    changeMetas(){
      this.setTitle(this.title)
    }
  }
}
</script>
