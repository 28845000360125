import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vSelect from 'vue-select'
import './assets/scss/index.scss'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0]
});

if(localStorage.Lang!=null) {
  i18n.locale=localStorage.Lang
}

if(i18n.locale == 'fr'){
  require('./validation-config-fr');
}

if(i18n.locale == 'en'){
  require('./validation-config-en');
}

Vue.component('v-select', vSelect)


Vue.config.productionTip = false
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')


