<template>
    <div class="mt-10">
        <h3 :class="setTextColor(titleColor)" class="mb-4 text-3xl">{{title}}</h3>
        <hr :class="setBorderColor(borderColor)" class="w-8/12">
    </div>
</template>

<style lang="scss" scoped>


</style>

<script>
    export default {
        name: "PortfolioTitle",
        props : {
            title: String,
            titleColor: String,
            borderColor: String
        },
        methods : {
            setTextColor (color) {
                return 'text-'+color;
            },
            setBorderColor (color) {
                return 'hr-'+color;
            }

        }
    }
</script>