<template>
    <hooper :settings="hooperSettings"
            class="h-auto"
            id="border-image-slider"
            :centerMode="slideList.length < 3 ? true : false"
            :infiniteScroll="slideList.length > 2 ? true : false">
        <slide class="flex justify-center h-full" v-for="(slide, index) in slideList" :key="index">
            <BorderImage :is-full-height-img="false" class="mt-14 mb-14 md:h-48 w-48 md:w-auto" :img="slide.logo.url" :alt="slide.logo.alt"/>
        </slide>
        <hooper-navigation slot="hooper-addons">
                <img slot="hooper-prev"  class="svg-gold w-6 active:outline-none active:outline-none" src="~@/assets/icons/left-arrow.svg" alt="">
                <img slot="hooper-next" class="svg-gold  w-6" src="~@/assets/icons/right-arrow.svg" alt="">
        </hooper-navigation>
    </hooper>
</template>

<script>
    import {
        Hooper,
        Slide,
        Navigation as HooperNavigation
    } from 'hooper';
    import 'hooper/dist/hooper.css';
    import BorderImage from "./BorderImage";

    export default {
        name: 'Slider',
        components: {
            BorderImage,
            Hooper,
            Slide,
            HooperNavigation
        },
        props : {
            slideList : Array
        },
        mounted: function () {
            if (this.slideList.length < 3 && window.screen.width >= 992) {
                document.querySelector('.hooper-track').classList.add('justify-center')
                document.querySelector('.hooper-track').classList.add('transform-none')
            }
        },
        data() {
            return {
                hooperSettings: {
                    itemsToShow: 1,
                    // centerMode: true,
                    // infiniteScroll:true,
                    breakpoints: {
                        992: {
                            centerMode: false,
                            itemsToShow: 2
                        },
                        1200: {
                            itemsToShow: 3
                        }
                    },
                    wheelControl: false
                }
            };
        }
    };
</script>

<style lang="scss">
    #border-image-slider{
        .hooper-prev{
            left:-15px;
        }
        .hooper-next{
            right:-15px;
        }
        .hooper-next, .hooper-prev{
            &.is-disabled{
                opacity:1;
            }
            padding:0 !important;
        }
            max-width:90%;
            margin:0 auto;

        .hooper-track{
            align-items:center;
        }
        .transform-none{
            transform: none !important;
        }
    }

</style>
