<template>
    <header class="background-purple flex flex-row-reverse items-center justify-center py-4 xl:justify-between xl:flex-row xl:px-10" :class="this.$parent.isBlur === true ? 'blur' : ''">
        <figure class="w-1/6 xl:hidden">
            <router-link to="/">
                <img :src="logo.url" :alt ="logo.alt" />
            </router-link>
        </figure>
        <nav class="w-72 xl:w-full flex flex-wrap bg-grey-darkest absolute xl:static xl:max-w-screen-xl xl:mx-auto  left-0" :class="navState ? 'top-0' : ''">
            <div v-if="!navState" @click="open" class="block xl:hidden pl-6">
                <button class="flex items-center">
                    <img  class="h-6 svg-beige" src="~@/assets/icons/menu.svg" alt="Icone du burger menu, trois lignes disposées en colonne">
                </button>
            </div>

            <div class="p-6 pt-9 xl:p-0 flex-grow xl:flex xl:items-center xl:w-auto  xl:block xl:h-auto background-purple h-screen" :class="navState ? 'show' : 'hidden'" id="nav-content">
                <button aria-hidden="true" class="flex items-center xl:hidden">
                    <img v-if="navState" @click="close" class="h-4 svg-beige" src="~@/assets/icons/close.svg" alt="Icone d'une croix">
                </button>
                <ul class="list-reset xl:flex justify-end flex-1 items-center text-white pt-8 xl:pt-0 xl:justify-between">
                    <li>
                        <figure class="w-32 hidden xl:block">
                            <router-link to="/" >
                                <img :src="logo.url" :alt ="logo.alt" />
                            </router-link>
                        </figure>
                    </li>
                    <li class="mr-3" >
                        <NavLink v-on:setClose="close" link="/" :text="pages[0]['title_'+$i18n.locale]" menu-item="home" />
                    </li>
                    <li aria-hidden="true" class="mr-3">
                        <NavLink v-on:setClose="close" link="/mon-savoir-faire" :text="pages[1]['title_'+$i18n.locale]" menu-item="services"/>
                    </li>
                    <li aria-hidden="true" class="mr-3">
                        <NavLink v-on:setClose="close" link="/votre-projet-pas-a-pas" :text="pages[4]['title_'+$i18n.locale]" menu-item="step-by-step"/>
                    </li>
                    <li aria-hidden="true" class="mr-3">
                        <NavLink v-on:setClose="close" link="/qui-suis-je" :text="pages[2]['title_'+$i18n.locale]" menu-item="about"></NavLink>
                    </li>
                    <li aria-hidden="true" class="mr-3">
                        <NavLink v-on:setClose="close" link="/mes-realisations" :text="pages[5]['title_'+$i18n.locale]" menu-item="portfolio"></NavLink>
                    </li>
                    <li aria-hidden="true" class="mr-3">
                        <NavLink v-on:setClose="close" link="/contact" :text="pages[3]['title_'+$i18n.locale]" menu-item="contact"></NavLink>
                    </li>
                    <li>
                        <VueSelect v-if="selectedLanguage" :data="languages" v-model="selectedLanguage" :value="selectedLanguage" v-on:valueSelect="onValueSelect" id="selectLang">
                            <template v-slot:SelectLabel>Langue</template>
                        </VueSelect>
                    </li>
                </ul>
            </div>
        </nav>
    </header>

</template>
<style  lang="scss">
    #selectLang{
        span{
            text-transform: uppercase;
        }
    }
</style>
<script>
    import NavLink from "../../components/NavLink";
    import VueSelect from "../../components/VueSelect";
    export default {
        name: "Header",
        components: {VueSelect, NavLink},
        props:{
            pages:Array,
            logo: Object
        },
        data: function (){
            return {
                navState : false,
                selectedLanguage: null,
                languages: [
                    {
                        title : 'fr',
                        img : 'france.svg'
                    },
                    {
                        title : 'en',
                        img : 'united-kingdom.svg'
                    }
                ]
            }
        },
        mounted() {
            if(this.$i18n.locale.includes("fr")){
                this.selectedLanguage = this.languages[0]
            } else{
                this.selectedLanguage = this.languages[1]
            }
        },
        methods: {
            open () {
                this.navState = true
                document.documentElement.style.overflow = 'hidden'
            },
            close(){
                this.navState = false
                document.documentElement.style.overflow = 'initial'
            },
            onValueSelect(value) {
                this.selectedLanguage = value;
                this.$i18n.locale=value.title;
                this.$emit('changeMetas')
                localStorage.Lang=value.title
            }
        }
    }
</script>