<template>
    <main class="mb-auto" v-if="page">
        <section class="background-purple p-8" :class="this.$parent.isBlur === true ? 'blur' : ''">
            <div class="lg:max-w-screen-xl flex flex-col items-center lg:flex-row lg:justify-around flex-wrap lg:mx-auto">
                <img class="w-64" :src="page.Images[5].url" :alt="page.Images[5].alt">
                <h1 class="w-full text-3xl mt-8 text-gold text-center">
                    {{page.Texts[0]['content_'+$i18n.locale]}}
                </h1>
                <v-runtime-template class="text-center mt-8 text-white" :template="replaceLinks(page.Texts[1]['content_'+$i18n.locale])"></v-runtime-template>
            </div>
        </section>
        <section class="background-gold p-8">
            <div class="lg:max-w-screen-xl lg:mx-auto ">
                <div class="flex flex-col items-center lg:flex-row lg:justify-between">
                    <div class="lg:w-5/12">
                        <h2 class="text-3xl text-purple mb-4" >
                            {{page.Texts[2]['content_'+$i18n.locale]}}
                        </h2>
                        <hr class="w-8/12 hr-blue"/>
                        <v-runtime-template class="mt-6 text-white" :template="replaceLinks(page.Texts[3]['content_'+$i18n.locale])"></v-runtime-template>
                    </div>
                    <Button url="/questionnaire-cahier-des-charges" class-list="w-auto mt-14 background-blue lg:mt-0 lg:w-5/12" :content="page.Texts[5]['content_'+$i18n.locale]"/>
                </div>
                <div class="mt-20 flex flex-col">
                    <div class="flex flex-col lg:flex-row lg:flex-wrap">
                        <div class="lg:w-5/12 lg:ml-auto">
                            <h2 class="text-3xl text-purple mb-4 text-right" >
                                {{page.Texts[4]['content_'+$i18n.locale]}}
                            </h2>
                            <hr class="w-8/12 hr-blue ml-auto"/>
                        </div>
                        <div class="lg:flex lg:flex-row lg:justify-between mt-6">
                            <v-runtime-template class="lg:w-5/12 text-white"  :template="replaceLinks(page.Texts[6]['content_'+$i18n.locale])"></v-runtime-template>
                            <v-runtime-template class="lg:w-5/12 text-white"  :template="replaceLinks(page.Texts[7]['content_'+$i18n.locale])"></v-runtime-template>
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row lg:justify-around">
                        <div class="flex flex-col items-center lg:w-3/12">
                            <h3 class="text-center text-md text-white mt-10 mb-8">
                                {{page.Texts[8]['content_'+$i18n.locale]}}
                            </h3>
                            <img :src="page.Images[0].url" :alt="page.Images[0].alt" class="w-full max-w-sm">
                        </div>

                        <figure class="w-full flex justify-center mt-10 mb-3 lg:w-1/12">
                            <img  src="~@/assets/icons/down-arrow-double.svg" class="svg-purple w-6 lg:hidden" alt="Flèches vers les bas">
                            <img  src="~@/assets/icons/right-arrow.svg" class="svg-purple w-6 hidden lg:inline " alt="Flèches vers les bas">
                        </figure>

                        <div class="flex flex-col items-center lg:w-3/12">
                            <h3 class="text-center text-md text-white mt-10 mb-8">
                                {{page.Texts[9]['content_'+$i18n.locale]}}
                            </h3>
                            <img :src="page.Images[1].url" :alt="page.Images[1].alt"  class="w-full max-w-sm">
                        </div>

                        <figure class="w-full flex justify-center mt-10 mb-3 lg:w-1/12">
                            <img  src="~@/assets/icons/down-arrow-double.svg" class="svg-purple w-6 lg:hidden" alt="Flèches vers les bas">
                            <img  src="~@/assets/icons/right-arrow.svg" class="svg-purple w-6 hidden lg:inline " alt="Flèches vers les bas">
                        </figure>

                        <div class="flex flex-col items-center lg:w-3/12">
                            <h3 class="text-center text-md text-white mt-10 mb-8">
                                {{page.Texts[10]['content_'+$i18n.locale]}}
                            </h3>
                            <img :src="page.Images[2].url" :alt="page.Images[2].alt" class="w-full max-w-sm">
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-center mt-20 lg:flex-row lg:justify-between">
                    <div class="lg:w-5/12">
                        <h2 class="text-3xl mb-4 text-purple">
                            {{page.Texts[13]['content_'+$i18n.locale]}}
                        </h2>
                        <hr class="w-8/12 hr-blue"/>
                        <v-runtime-template class="mt-6 text-white"  :template="replaceLinks(page.Texts[11]['content_'+$i18n.locale])"></v-runtime-template>
                    </div>

                    <BorderImage :is-full-height-img="false" :is-full-width-img="true" class="mw-6  lg:h-60 h-36 w-9/12 mt-14 self-center md:w-4/12 lg:w-5/12" :img="page.Images[3].url" :alt="page.Images[3].alt"/>

                </div>

                <div class="flex flex-col items-center mt-20 lg:flex-row-reverse lg:justify-between">
                    <div class="lg:w-5/12 ">
                        <h2 class="text-3xl  mb-4 text-right text-purple">
                            {{page.Texts[14]['content_'+$i18n.locale]}}
                        </h2>
                        <hr class="w-8/12 hr-blue ml-auto"/>
                        <v-runtime-template class="mt-10 text-white" :template="replaceLinks(page.Texts[12]['content_'+$i18n.locale])"></v-runtime-template>
                    </div>
                    <BorderImage :is-full-height-img="false" :is-full-width-img="true" class="mw-15 mb-10  lg:h-60 h-36 w-9/12 mt-14 self-center md:w-4/12 lg:w-5/12" :img="page.Images[4].url" :alt="page.Images[4].alt"/>
                </div>
            </div>

        </section>

    </main>
</template>

<style scoped lang="scss">


</style>


<script>
    import Button from "../components/Button";
    import BorderImage from "../components/BorderImage";
    import axios from "axios";
    import renderMixin from "../mixins/renderMixin";
    import VRuntimeTemplate from "v-runtime-template";

    export default {
        components: {BorderImage, Button,  VRuntimeTemplate},
        mixins: [ renderMixin],
        data: function(){
          return {
              page:null
          }
        },
        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL+'/api/pages/6')
                .then(response => (
                        this.page = response.data,
                        this.sendDataToParent(this.page)
                    )
                )
        }
    }
</script>