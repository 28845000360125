<template>
    <main class="mb-auto" v-if="page">
        <section class="background-purple p-8" :class="this.$parent.isBlur === true ? 'blur' : ''">
            <div class="lg:max-w-screen-xl flex flex-col items-center lg:flex-row lg:justify-around flex-wrap lg:mx-auto">
                <img class="w-64" :src="page.Images[0].url" :alt="page.Images[0].alt">
                <h1 class="w-full text-3xl mt-8 text-gold text-center">{{page['title_'+$i18n.locale]}}</h1>
                <v-runtime-template class="text-center mt-8 text-white" :template="replaceLinks(page.Texts[0]['content_'+$i18n.locale])"></v-runtime-template>
            </div>
        </section>
        <section class="background-gold p-8 " v-if="web" id="web">
            <div class="lg:max-w-screen-xl lg:mx-auto ">
                <h2 class="text-purple text-center text-3xl uppercase">{{web[0].type['title_'+$i18n.locale]}}</h2>
                <v-runtime-template class="text-center mt-8 text-white" :template="replaceLinks(web[0].type['lead_'+$i18n.locale])"></v-runtime-template>
                <div class="flex flex-col md:flex-row md:justify-around lg:justify-between lg:mx-12 mt-8 md:mt-20 one-web" v-for="service in web" :key="service.id">
                    <div class="md:w-5/12 lg:w-5/12" :class="service.id%2 == 0 ? 'order-1':''">
                        <PortfolioTitle border-color="blue" :title="service['title_'+$i18n.locale]" title-color="purple"/>
                        <v-runtime-template class="mt-8 text-white" :template="replaceLinks(service['description_'+$i18n.locale])"></v-runtime-template>
                    </div>
                    <BorderImage :img="service.img.url" :alt="service.img.alt" class="mw-15  lg:h-60 h-36 w-9/12 mt-14 self-center md:w-4/12 lg:w-5/12" :is-full-height-img="false" :is-full-width-img="true"/>
                </div>
                <div class="flex flex-row justify-around  mt-20">
                    <Button url="/contact" :content="page.Texts[1]['content_'+$i18n.locale]" class-list="background-blue"/>
                </div>
            </div>
        </section>
        <section class="background-blue p-8 " v-if="print" id="print">
            <div class="lg:max-w-screen-xl lg:mx-auto ">
                <h2 class="text-gold text-center text-3xl uppercase">{{print[0].type['title_'+$i18n.locale]}}</h2>
                <p class="text-center mt-8 text-white">{{print[0].type['lead_'+$i18n.locale]}}</p>
                <div class="flex flex-col md:flex-row md:justify-around lg:justify-between lg:mx-12 mt-8 md:mt-20" v-for="service in print" :key="service.id">
                    <div class="md:w-5/12 lg:w-5/12" :class="service.id%2 == 0 ? 'order-1':''">
                        <PortfolioTitle border-color="purple" :title="service['title_'+$i18n.locale]" title-color="gold"/>
                        <v-runtime-template class="mt-8 text-white" :template="replaceLinks(service['description_'+$i18n.locale])"></v-runtime-template>
                    </div>
                    <BorderImage :img="service.img.url" :alt="service.img.alt" class="mw-15  lg:h-60 h-36 w-9/12 mt-14 self-center md:w-4/12 lg:w-5/12" :is-full-height-img="false" :is-full-width-img="true"/>
                </div>
                <div class="flex flex-row justify-around  mt-20">
                    <Button url="/contact" :content="page.Texts[1]['content_'+$i18n.locale]" class-list="background-gold"/>
                </div>
            </div>
        </section>
        <section class="background-gold p-8 " v-if="branding" id="branding">
            <div class="lg:max-w-screen-xl lg:mx-auto ">
                <h2 class="text-purple text-center text-3xl uppercase">{{branding[0].type['title_'+$i18n.locale]}}</h2>
                <p class="text-center mt-8 text-white">{{branding[0].type['lead_'+$i18n.locale]}}</p>
                <div class="flex flex-col md:flex-row-reverse md:justify-around lg:justify-between lg:mx-12 mt-8 md:mt-20" v-for="service in branding" :key="service.id">
                    <div class="md:w-5/12 lg:w-5/12" :class="service.id%2 == 0 ? 'order-1':''">
                        <PortfolioTitle border-color="blue" :title="service['title_'+$i18n.locale]" title-color="purple"/>
                        <v-runtime-template class="mt-8 text-white" :template="replaceLinks(service['description_'+$i18n.locale])"></v-runtime-template>
                    </div>
                    <BorderImage :img="service.img.url" :alt="service.img.alt" class=" mw-15  lg:h-60 h-36 w-9/12 mt-14 self-center md:w-4/12 lg:w-5/12" :is-full-height-img="false" :is-full-width-img="true"/>
                </div>
                <div class="flex flex-row justify-around  mt-20">
                    <Button url="/contact" :content="page.Texts[1]['content_'+$i18n.locale]" class-list="background-blue"/>
                </div>
            </div>
        </section>
    </main>
</template>

<style scoped lang="scss">
    @media screen and (max-width: 1023px) {
        .mw-15{
            max-width:15rem;
        }
    }
    @media screen and (max-width: 767px) {
        #web >div>div:nth-child(even), #print >div>div:nth-child(even){
            flex-direction:column-reverse;
        }
        #branding >div>div:nth-child(odd){
            flex-direction:column-reverse;
        }
    }

</style>


<script>
    import PortfolioTitle from "../components/PortfolioTitle";
    import BorderImage from "../components/BorderImage";
    import Button from "../components/Button";
    import axios from "axios";
    import renderMixin from "../mixins/renderMixin";
    import VRuntimeTemplate from "v-runtime-template";

    export default {
        components: {Button, BorderImage, PortfolioTitle, VRuntimeTemplate},
        data () {
            return {
                page:null,
                web:null,
                print:null,
                branding:null
            }
        },
        mixins: [ renderMixin],

        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL+'/api/pages/2')
                .then(response => (
                        this.page = response.data,
                            axios
                                .get(process.env.VUE_APP_API_URL+'/api/services/types/1')
                                .then(response => (
                                        this.web = response.data,
                                            axios
                                                .get(process.env.VUE_APP_API_URL+'/api/services/types/2')
                                                .then(response => (
                                                        this.print = response.data,
                                                            axios
                                                                .get(process.env.VUE_APP_API_URL+'/api/services/types/3')
                                                                .then(response => (
                                                                        this.branding = response.data,
                                                                        this.sendDataToParent(this.page)
                                                                    )
                                                                )
                                                    )
                                                )
                                    )
                                )
                    )
                )

        }
    }
</script>