// src/mixins/formMixin.js

export default {
    methods: {
        verifyFileExist(files){
            if(files.length <= 0){
                return 'Vous devez séléctionner un fichier';
            }
            return '';
        },
        verifyFileSize(file){
                if(file.size >= 1000000){
                   return false;
                }
                return true;
        },
        changeButton(idFirst, idSecond){
            document.querySelector(idFirst).className+=' hidden';
            document.querySelector( idSecond).classList.remove('hidden');
            document.querySelector( idSecond).className+=' block';
        },
        setFormWithFiles(files, form){
            const fd = new FormData();
            if(files != null){
                for (const i of Object.keys(files)) {
                    fd.append('files', files[i])
                }
            }

            // Append form values inside
            Object.entries(form).forEach(
                ([key, value]) => {
                    if (value !== null && value !== '') {
                        if(key == "visual_identity"
                            && value.includes('Non')
                            && this.form.visual_identity_other != null){
                            value=value + '. Mot clés, phrase d\'accroche : '+this.form.visual_identity_other
                        }
                        fd.append(`${key}`, value);
                    }
                },
            );
            return fd
        },
        removeFile(files, index){
            files.splice(index,1)
            return files
        },
    }
};