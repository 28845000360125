<template>
    <main class="mb-auto" v-if="page">
        <section class="background-purple p-8">
            <div class="xl:max-w-screen-xl xl:mx-auto xl:px-48 xl:justify-between">
                <h1 class="text-gold text-center text-3xl mb-3 w-full">{{page['title_'+$i18n.locale]}}</h1>
                <v-runtime-template class="leading-9 text-white" :template="replaceLinks(page.Texts[0]['content_'+$i18n.locale])"></v-runtime-template>
            </div>
        </section>
    </main>
</template>

<style  lang="scss">
    body{
        background: $purple;
    }
</style>

<script>
    import axios from "axios";
    import VRuntimeTemplate from "v-runtime-template";
    import renderMixin from "../mixins/renderMixin";

    export default {
        components: {
            VRuntimeTemplate
        },
        mixins: [ renderMixin],

        data() {
            return {
                page: null
            }
        },
        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL + '/api/pages/8')
                .then(response => (
                        this.page = response.data,
                        this.sendDataToParent(this.page)
                    )
                )
        }
    }
</script>
