import VRuntimeTemplate from "v-runtime-template";
<template>
    <main class="mb-auto" v-if="page">
        <section class="background-purple p-8 ">
            <div class="lg:max-w-screen-xl xl:mx-auto flex flex-col xl:flex-row xl:flex-wrap xl:justify-between items-center  text-center"
            >
                <div class="flex flex-col items-center w-full">
                    <img class="w-64" :src="page.Images[0].url" :alt="page.Images[0].alt">
                    <h1 class="text-white text-3xl mt-8 text-gold">{{page['title_'+$i18n.locale]}}</h1>
                </div>
                <div class="xl:flex xl:flex-row xl:justify-between 2xl:max-w-screen-lg 2xl:mx-auto">
                    <div class="xl:w-1/2 text-left flex flex-col mt-16">
                        <div class="flex flex-col items-center text-center xl:text-left">
                            <v-runtime-template class="mt-8 text-white" :template="replaceLinks(page.Texts[0]['content_'+$i18n.locale])"></v-runtime-template>
                            <img class="mt-8 h-48 xl:hidden" :src="page.Images[1].url" :alt="page.Images[1].alt">
                        </div>
                        <div class="flex flex-col items-center text-center  xl:text-left">
                            <v-runtime-template class="mt-8 text-white" :template="replaceLinks(page.Texts[1]['content_'+$i18n.locale])"></v-runtime-template>
                            <img class="mt-8 h-48 xl:hidden" :src="page.Images[2].url" :alt="page.Images[2].alt">
                        </div>
                        <div class="flex flex-col items-center text-center  xl:text-left">
                            <v-runtime-template class="mt-8 text-white" :template="replaceLinks(page.Texts[2]['content_'+$i18n.locale])"></v-runtime-template>
                            <img class="mt-8 h-48 xl:hidden" :src="page.Images[3].url" :alt="page.Images[3].alt">
                        </div>
                    </div>
                    <div class="hidden xl:block xl:w-5/12">
                        <img class="h-64 mt-10 ml-44" :src="page.Images[1].url" :alt="page.Images[1].alt">
                        <img class="mt-8 h-64 mt-7" :src="page.Images[2].url" :alt="page.Images[2].alt">
                        <img class="mt-8 h-56 ml-24 mt-2" :src="page.Images[3].url" :alt="page.Images[3].alt">
                    </div>
                </div>

            </div>
        </section>
    </main>
</template>

<style scoped lang="scss">
.mt-7{
    margin-top:-7rem;
}
    .mt-2{
        margin-top:-2rem;
    }
</style>
<script>
    import axios from "axios";
    import renderMixin from "../mixins/renderMixin";
    import VRuntimeTemplate from "v-runtime-template";

    export default {

        data() {
            return {
                page: null,
            }
        },
        mixins: [ renderMixin],
        components:{
            VRuntimeTemplate
        },
        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL + '/api/pages/3')
                .then(response => (
                        this.page = response.data,
                        this.sendDataToParent(this.page)
                    )
                )

        }
    }
</script>