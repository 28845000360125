<template>
    <main class="mb-auto" v-if="page && creations">
        <section class="background-purple p-8" :class="this.$parent.isBlur === true ? 'blur' : ''">
            <div class="lg:max-w-screen-md lg:mx-auto flex flex-col items-center md:flex-row md:flex-wrap md:justify-around lg:justify-between">
                <h1 class="w-full text-3xl mt-8 text-gold text-center">{{page['title_'+$i18n.locale]}}</h1>
                <BorderImage
                        v-for="creation in creations"
                        :key="creation.index"
                        class="w-48 md:w-5/12  mt-24"
                        :is-full-height-img="false"
                       :is-full-width-img="true"
                        @click.native.stop="showModal(creation.medias, creation['title_'+$i18n.locale])"
                        second-border="gold-border"
                        :img="creation.cover.url" :alt="creation.cover.alt"
                />
            </div>
        </section>
        <section v-if="modalOpen" class="background-purple px-5 py-10 fixed" id="modal" v-click-outside="closeModal">
            <i @click="closeModal" class="fas fa-times text-xl text-gold absolute right-3 top-3 md:right-10 md:top-10"></i>
            <h2 class="text-gold text-center text-2xl">{{slideTitle}}</h2>
            <PortfolioSlider :slide-list="slideList" class="mt-10"/>
        </section>

    </main>
</template>

<style scoped lang="scss">
    #modal{
        border:5px solid $gold;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:80vw;
        height:auto;
        z-index:3;
    }
</style>


<script>
    import BorderImage from "../components/BorderImage";
    import ClickOutside from "vue-click-outside";
    import PortfolioSlider from "../components/PortfolioSlider";
    import axios from "axios";
    import renderMixin from "../mixins/renderMixin";

    export default {
        components: {PortfolioSlider,  BorderImage},
        data () {
            return {
                slideList: [],
                slideTitle:null,
                modalOpen: false,
                creations:null,
                page:null
            }
        },
        mixins: [ renderMixin],
        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL+'/api/pages/7')
                .then(response => (
                        this.page = response.data,
                            axios
                                .get(process.env.VUE_APP_API_URL+'/api/projects')
                                .then(response => (
                                        this.creations = response.data,
                                        this.sendDataToParent(this.page)
                                    )
                                )
                    )
                )

        },
        methods : {
            showModal (medias, title) {
                this.slideList =  medias
                this.slideTitle = title
                this.modalOpen = true;
                this.$emit('setBlur', true)
                document.documentElement.style.overflow = 'hidden'
            },
            closeModal () {
                if(this.modalOpen === true) {
                    this.slideList =  []
                    this.modalOpen = false;
                    this.$emit('setBlur', false)
                    document.documentElement.style.overflow = 'initial'

                }
            },
        },
        directives: {
            ClickOutside
        },
    }
</script>