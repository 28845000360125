<template>
   <figure class="relative">
       <div :class="firstBorder" class="bottom-4 right-4"></div>
       <div :class="secondBorder" class="top-4 left-4"></div>
       <img loading="lazy" class="relative max-h-full lg:h-auto object-cover" :class="getImgSize" :src="img" :alt="alt">
   </figure>
</template>
<script>
    export default {
        name: "BorderImage",
        props: {
            img: String,
            alt: String,
            firstBorder: {
                type: String,
                default: 'white-border'
            },
            secondBorder: {
                type: String,
                default: 'purple-border'
            },
            isFullHeightImg: {
                type: Boolean,
                default: true
            },
            isFullWidthImg: {
                type: Boolean,
                default: false
            }
        },
        computed :{
            getImgSize (){
                let classToApply = '';
                if (this.isFullHeightImg === true) {
                    classToApply+='h-full'
                }
                if(this.isFullWidthImg === true){
                    classToApply+='w-full'
                }
                return classToApply;
            }
        }
    }
</script>
<style scoped lang="scss">
    div{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
    }

    .white-border{
        border: 5px solid $beige;
    }

    .purple-border{
        border: 5px solid $purple;
    }

    .gold-border{
        border: 5px solid $gold;
    }

</style>