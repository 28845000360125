<template>
        <ValidationObserver  ref="observer" class="px-5">
            <p class="mt-5 text-gold" v-if="api_message != ''" >{{api_message}}</p>
            <v-runtime-template class="mt-5 text-white" v-if="submitSuccess" :template="replaceLinks(texts[14]['content_'+$i18n.locale])"></v-runtime-template>
            <v-runtime-template class="mt-5 text-gold" v-if="isErrors" :template="replaceLinks(texts[15]['content_'+$i18n.locale])"></v-runtime-template>

            <form v-if="!submitSuccess" v-on:submit.prevent="submitForm" id="survey_form" action="#" method="post" class="mt-10 flex flex-col">
                        <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <label :class="errors.length > 0 ? 'text-gold font-bold':''" for="survey_form_name" >
                                {{texts[7]['content_'+$i18n.locale]}}
                            </label>
                            <input type="text" id="survey_form_name"  v-model="form.client_name"/>
                            <span  class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <ValidationProvider rules="required|email" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <label :class="errors.length > 0 ? 'text-gold font-bold':''" for="survey_form_email" class="mt-5">
                                {{texts[8]['content_'+$i18n.locale]}}
                            </label>
                            <input type="text" id="survey_form_email"  v-model="form.email"/>
                           <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div class="flex flex-row flex-wrap items-center">
                            <label for="project_form_document" class="mt-5 w-full md:w-1/2">
                                <span >
                                    {{texts[9]['content_'+$i18n.locale]}}
                                </span><br>
                                <small>
                                    {{texts[10]['content_'+$i18n.locale]}}
                                </small>
                            </label>
                            <label for="project_form_document" id="files"  class="w-full md:w-1/2 h-1/2 custom-file-upload background-gold uppercase font-bold px-7 py-2 mt-5 w-72 text-center">
                                {{texts[11]['content_'+$i18n.locale]}}
                            </label>
                        </div>

                        <input accept=".pdf, .jpeg, .jpg, .png, .zip, .ppt, .pptx, .doc, .docx" type="file" id="project_form_document" @change="handleFileUpload()" ref="file" multiple>
                        <ul class="mt-3 ">
                            <li class="flex" v-for="(file, index) in this.files" :key="index" >
                               <span class="mw-11/12 break-all text-white">{{file['name']}}</span>
                               <i title="Supprimer le fichier" @click="removeFile(files, index)" class="leading-6 text-purple fas fa-times ml-5"></i>
                            </li>
                        </ul>
                        <p v-if="fileErrors" class="invalid-feedback">{{fileErrors}}</p>
                        <LoadingButton id="project"  bg="background-gold" custom-style="lg:w-80 lg:self-end" :text-loading="texts[16]['content_'+$i18n.locale]" :text-button="texts[12]['content_'+$i18n.locale]"/>
            </form>
        </ValidationObserver>
</template>

<style lang="scss">
    #survey_form{
        input[type="text"], input[type="number"], input[type="date"] {
            border-bottom: 3px solid $beige;
        }
        label{
            color: $beige;
        }
    }
</style>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import formMixin from '../mixins/formMixin'
    import axios from "axios";
    import renderMixin from "../mixins/renderMixin";
    import VRuntimeTemplate from "v-runtime-template";
    import LoadingButton from "./LoadingButton";

    export default {
        name: "AskProjectForm",
        components: {
            LoadingButton,
            ValidationProvider,
            ValidationObserver,
            VRuntimeTemplate
        },
        mixins: [formMixin, renderMixin],
        props: {
            texts: Array
        },
        data(){
            return{
                form: {
                    client_name: '',
                    email: '',
                },
                files: [],
                submitted: false,
                api_message: '',
                submitSuccess: false,
                isErrors: false,
                fileErrors: ''
            }
        },
        methods : {
            submitForm(){
                this.api_message='';
                this.submitSuccess=false;
                this.isErrors = false;
                this.fileErrors = '';

                this.fileErrors = this.verifyFileExist(this.files)

                this.$refs.observer.validate().then(valid => {
                    if (valid && this.fileErrors == '') {
                        //Move button
                       this.changeButton('#submitBtn_project', '#loadingBtn_project')


                        //Set Form Data
                        var fd =this.setFormWithFiles(this.files, this.form)

                        //Request
                        axios.post(
                            process.env.VUE_APP_API_URL+'/api/ask_project',
                            fd,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        ).then(() => {
                            this.submitSuccess=true;
                        })
                            .catch((error) => {
                                this.api_message = "Une erreur est survenue ("+error+")",
                                this.changeButton( '#loadingBtn_project','#submitBtn_project')
                            }).finally(() => {
                            //Perform action in always
                        });

                    } else{
                        this.isErrors = true;
                    }
                });
            },
            handleFileUpload(){
                this.fileErrors=''
                this.$refs.file.files.forEach(file =>{
                    if(this.verifyFileSize(file) == true){
                        this.files.push(file)
                    } else{
                        this.fileErrors = "Un ou plusieurs fichiers n'ont pas été ajouté car il(s) dépasse(nt) 1 Mo"
                    }
                })
            }
        }
    }
</script>
