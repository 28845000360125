<template>
    <section class="background-gold p-8 lg:py-16">
        <div class=" flex flex-col flex-wrap items-center lg:max-w-screen-xl mx-auto lg:flex-row lg:justify-between lg:items-center" v-if="page">
            <p class="lg:w-4/12 font-bold text-white">{{page.Texts[0]['content_'+$i18n.locale]}}</p>
            <Button url="/questionnaire-cahier-des-charges" class-list="mt-14 background-blue lg:mt-0 lg:w-5/12 text-white" :content="page.Texts[1]['content_'+$i18n.locale]"/>
        </div>
    </section>
</template>

<script>
    import Button from "./Button";
    import axios from "axios";
    import renderMixin from "../mixins/renderMixin";

    export default {
        name: "SurveyPropose",
        components: {Button},
        mixins: [ renderMixin],

        data() {
            return {
                page:null,
                text:null,
                btn:null
            }
        },
        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL+'/api/pages/1')
                .then(response => (
                    this.page = response.data
                ))
        }
    }
</script>
