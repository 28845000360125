<template>
    <ValidationObserver  ref="observer" tag="div" class="px-5">
        <p class="mt-5 text-purple" v-if="api_message != ''" >{{api_message}}</p>
        <v-runtime-template class="mt-5 text-white" v-if="submitSuccess" :template="replaceLinks(texts[14]['content_'+$i18n.locale])"></v-runtime-template>
        <v-runtime-template class="mt-5 text-purple" v-if="isErrors" :template="replaceLinks(texts[15]['content_'+$i18n.locale])"></v-runtime-template>

        <form v-if="!submitSuccess" v-on:submit.prevent="submitForm" id="contact_form" action="#" method="post" class="mt-10 flex flex-col">
            <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                <label :class="errors.length > 0 ? 'text-purple font-bold':'text-blue'" for="contact_form_name" >
                    {{texts[2]['content_'+$i18n.locale]}}
                </label>
                <input v-model="form.client_name" type="text" id="contact_form_name" class="leading-4" />
                <span v-if="errors.length > 0"  class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                <label :class="errors.length > 0 ? 'text-purple font-bold':'text-blue'" for="contact_form_email" class="mt-5">
                    {{texts[3]['content_'+$i18n.locale]}}
                </label>
                <input v-model="form.email" type="text" id="contact_form_email" class="leading-4" />
                <span v-if="errors.length > 0"  class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                <label :class="errors.length > 0 ? 'text-purple font-bold':'text-blue'" for="contact_form_message" class="mt-5">
                    {{texts[4]['content_'+$i18n.locale]}}
                </label>
                <textarea v-model="form.message" id="contact_form_message"  class="leading-4" rows="5"></textarea>
                <span v-if="errors.length > 0"  class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
            <LoadingButton id="contact"  bg="background-blue" custom-style="lg:w-80 lg:self-end" :text-loading="texts[16]['content_'+$i18n.locale]" :text-button="texts[5]['content_'+$i18n.locale]"/>
        </form>
    </ValidationObserver>
</template>

<style lang="scss" scoped>
    textarea:focus{
        border-color:$blue
    }
</style>
<script>
    import axios from 'axios';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import formMixin from '../mixins/formMixin'
    import renderMixin from "../mixins/renderMixin";
    import VRuntimeTemplate from "v-runtime-template";
    import LoadingButton from "./LoadingButton";

    export default {
        name: "ContactForm",
        components: {
            LoadingButton,
            ValidationProvider,
            ValidationObserver,
            VRuntimeTemplate
        },
        mixins: [formMixin, renderMixin],
        props: {
            texts: Array
        },
        data ()  {
            return {
                form: {
                    client_name: '',
                    email: '',
                    message: ''
                },
                api_message: '',
                submitSuccess: false,
                isErrors: false,
                fileErrors: false
            }
        },
        methods : {
            submitForm(){
                this.api_message='';
                this.submitSuccess=false;
                this.isErrors = false;

                this.$refs.observer.validate().then(valid => {
                    if (valid) {
                        this.changeButton('#submitBtn_contact', '#loadingBtn_contact')

                        axios.post(
                            process.env.VUE_APP_API_URL+'/api/contact',
                            this.form
                        ).then(() => {
                            this.submitSuccess=true;
                        })
                            .catch((error) => {
                                this.api_message = "Une erreur est survenue ("+error+")",
                                this.changeButton( '#loadingBtn_contact', '#submitBtn_contact')
                            }).finally(() => {
                            //Perform action in always
                        });

                    } else{
                        this.isErrors = true;
                    }
                });
            }
        }
    }
</script>
