export default {
    data() {
        return {
            prevRoute: null
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    methods: {
        replaceLinks (text){
            let newText=text.replaceAll(
                '<a href="-1',
                '<a target="_self" @click.stop.prevent="$router.push(prevRoute)" href="retour-en-arriere'
            )

            newText=newText.replaceAll(
                '<a href="/',
                '<a target="_self" @click.stop.prevent="$router.push($event.currentTarget.getAttribute(\'href\'))" href="/'
            )

            return '<div>'+newText+'</div>'
        },
        sendDataToParent(page){
            if(page['title_fr'] != "Accueil"){
                this.$emit('setTitle',
                    {
                        'fr' : page['title_fr'],
                        'en' : page['title_en']
                    }
                )
            }

            this.$emit('removeLoader'),
            document.querySelector('meta[name="description"][lang="fr"]').setAttribute("content",page.meta_description_fr);
            document.querySelector('meta[name="description"][lang="en"]').setAttribute("content",page.meta_description_en);
            document.querySelector('meta[property="og:description"][lang="fr"]').setAttribute("content",page.meta_description_fr);
            document.querySelector('meta[property="og:description"][lang="en"]').setAttribute("content",page.meta_description_en);
            document.querySelector('meta[property="og:title"][lang="fr"]').setAttribute("content",page.title_fr);
            document.querySelector('meta[property="og:title"][lang="en"]').setAttribute("content",page.title_en);
            document.querySelector('meta[property="og:url"]').setAttribute("content",window.location.href);

        }
    }
};