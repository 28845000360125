<template>
    <main class="mb-auto " v-if="page">
        <section class="background-purple p-8">
            <div class="lg:max-w-screen-lg lg:mx-auto">
                <h1 class="text-3xl mt-8 text-gold text-center">{{page['title_'+$i18n.locale]}}</h1>
                <h2 class="text-3xl mt-16  text-center lg:text-left text-white">
                    {{page.Texts[0]['content_'+$i18n.locale]}}
                </h2>
                <p class="mt-5 text-gold" v-if="api_message != ''" >{{api_message}}</p>
                <p class="mt-5 text-white" v-if="submitSuccess" >
                    {{page.Texts[36]['content_'+$i18n.locale]}}
                </p>
                <p class="mt-5 text-gold" v-if="isErrors">
                    {{page.Texts[37]['content_'+$i18n.locale]}}
                </p>
            </div>
        </section>

        <ValidationObserver  v-if="!submitSuccess" ref="observer">
            <form v-on:submit.prevent="submitForm" id="survey_form" action="#" method="post">
                <section class="mt-5  p-8 background-purple">
                    <div class="lg:max-w-screen-md lg:mx-auto flex flex-col">
                        <h3 class="text-gold text-lg mb-10 font-medium">
                            {{page.Texts[1]['content_'+$i18n.locale]}}
                        </h3>

                        <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <label :class="errors.length > 0 ? 'text-gold font-bold':''" for="survey_form_name" >
                                {{page.Texts[2]['content_'+$i18n.locale]}}
                            </label>
                            <input type="text" id="survey_form_name"  v-model="form.client_name"/>
                            <span  class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <ValidationProvider rules="required|email" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <label :class="errors.length > 0 ? 'text-gold font-bold':''" for="survey_form_email" class="mt-5">
                                {{page.Texts[3]['content_'+$i18n.locale]}}
                            </label>
                            <input type="text" id="survey_form_email"  v-model="form.email"/>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>


                        <label for="survey_form_phone" class="mt-5">
                            {{page.Texts[4]['content_'+$i18n.locale]}}
                        </label>
                        <input type="text" id="survey_form_phone" v-model="form.phone"/>

                        <h3 class="text-gold text-lg mt-16 font-medium">
                            {{page.Texts[5]['content_'+$i18n.locale]}}
                        </h3>


                        <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">

                            <p  :class="errors.length > 0 ? 'text-gold font-bold':'text-white'" class="mt-10">
                                {{page.Texts[6]['content_'+$i18n.locale]}}
                            </p>
                                <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                    <input class="mr-5" type="radio" id="survey_form_type_1"
                                           :value="page.Texts[7].content_fr" v-model="form.type">
                                    <label for="survey_form_type_1">
                                        {{page.Texts[7]['content_'+$i18n.locale]}}
                                    </label>
                                </div>
                                <div class="flex flex-row items-center mt-3 ml-7 lg:ml-16">
                                    <input class="mr-5" type="radio" id="survey_form_type_2"
                                           :value="page.Texts[8].content_fr"  v-model="form.type">
                                    <label for="survey_form_type_2">
                                        {{page.Texts[8]['content_'+$i18n.locale]}}
                                    </label>
                                </div>
                                <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>


                        <label for="survey_form_website_name" class="mt-5">
                            {{page.Texts[9]['content_'+$i18n.locale]}}
                        </label>
                        <input type="text" id="survey_form_website_name"  v-model="form.website_name" />

                        <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <label :class="errors.length > 0 ? 'text-gold font-bold':''" for="survey_form_services" class="mt-5">
                                {{page.Texts[10]['content_'+$i18n.locale]}}
                            </label>
                            <input type="text" id="survey_form_services"  v-model="form.services" />
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <ValidationProvider rules="required|numeric" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <label :class="errors.length > 0 ? 'text-gold font-bold':''" for="survey_form_pages_number" class="mt-5">
                                {{page.Texts[11]['content_'+$i18n.locale]}}
                            </label>
                            <input type="text" id="survey_form_pages_number"  v-model="form.pages_number"  />
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <label for="survey_form_budget" class="mt-5">
                            {{page.Texts[12]['content_'+$i18n.locale]}}
                        </label>
                        <input type="text" id="survey_form_budget"  v-model="form.budget" />

                        <label for="survey_form_start_date" class="mt-5">
                            {{page.Texts[13]['content_'+$i18n.locale]}}
                        </label>
                        <input type="date" id="survey_form_start_date" class="text-white"  v-model="form.start_date" />
                    </div>
                </section>
                <section class="background-gold mt-20 p-8">
                    <div class="lg:max-w-screen-md lg:mx-auto  flex flex-col">
                        <h3 class="text-blue text-lg  font-medium">
                            {{page.Texts[14]['content_'+$i18n.locale]}}
                        </h3>

                        <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <p  :class="errors.length > 0 ? 'text-purple font-bold':'text-white'" class="mt-10">
                                {{page.Texts[15]['content_'+$i18n.locale]}}
                            </p>

                            <div class="flex flex-col mt-5 items-start ml-7 lg:ml-16">
                                <div class="flex">
                                    <input class="mr-5" type="radio" id="survey_form_visual_identity_1"
                                           :value="page.Texts[16].content_fr"  v-model="form.visual_identity">
                                    <label for="survey_form_visual_identity_1">
                                        {{page.Texts[16]['content_'+$i18n.locale]}}
                                    </label>
                                </div>

                                <div class="ml-9">
                                    <label for="survey_form_visual_identity_infos">
                                        <small>
                                            {{page.Texts[17]['content_'+$i18n.locale]}}
                                        </small>
                                    </label>
                                    <input type="text" id="survey_form_visual_identity_infos"  v-model="form.visual_identity_other"/>
                                </div>
                            </div>
                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="radio" id="survey_form_visual_identity_2"
                                       :value="page.Texts[18].content_fr" v-model="form.visual_identity">
                                <label for="survey_form_visual_identity_2">
                                    {{page.Texts[18]['content_'+$i18n.locale]}}
                                </label>
                            </div>
                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="radio" id="survey_form_visual_identity_3"
                                       :value="page.Texts[19].content_fr" v-model="form.visual_identity">
                                <label for="survey_form_visual_identity_3">
                                    {{page.Texts[19]['content_'+$i18n.locale]}}
                                </label>
                            </div>
                            <span class="invalid-feedback">{{ errors[0] }}</span>

                            <p class="mt-10 ml-7 lg:ml-16 text-white">
                                {{page.Texts[20]['content_'+$i18n.locale]}}
                            </p>
                        </ValidationProvider>


                        <label for="survey_form_competitors" class="mt-5">
                            {{page.Texts[21]['content_'+$i18n.locale]}}
                        </label>
                        <input type="text" id="survey_form_competitors"  v-model="form.competitors" />

                        <h3 class="text-blue text-lg mt-20 font-medium">
                            {{page.Texts[22]['content_'+$i18n.locale]}}
                        </h3>
                        <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <p :class="errors.length > 0 ? 'text-purple font-bold':'text-white'" class="mt-10">
                                {{page.Texts[23]['content_'+$i18n.locale]}}
                            </p>

                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="checkbox" id="survey_form_pages_1"
                                       :value="page.Texts[24].content_fr" v-model="form.pages">
                                <label for="survey_form_pages_1">
                                    {{page.Texts[24]['content_'+$i18n.locale]}}
                                </label>
                            </div>
                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="checkbox" id="survey_form_pages_2"
                                       :value="page.Texts[25].content_fr" v-model="form.pages">
                                <label for="survey_form_pages_2">
                                    {{page.Texts[25]['content_'+$i18n.locale]}}
                                 </label>
                            </div>
                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="checkbox" id="survey_form_pages_3"
                                       :value="page.Texts[26].content_fr" v-model="form.pages">
                                <label for="survey_form_pages_3">
                                    {{page.Texts[26]['content_'+$i18n.locale]}}
                                </label>
                            </div>
                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="checkbox" id="survey_form_pages_4"
                                       :value="page.Texts[27].content_fr" v-model="form.pages">
                                <label for="survey_form_pages_4">
                                    {{page.Texts[27]['content_'+$i18n.locale]}}
                                </label>
                            </div>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <ValidationProvider rules="required" v-slot="{ errors }" tag="div" class="flex flex-col flex-wrap">
                            <p :class="errors.length > 0 ? 'text-purple font-bold':'text-white'" class="mt-10">
                                {{page.Texts[28]['content_'+$i18n.locale]}}
                            </p>

                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="checkbox" id="survey_form_functionality_1"
                                       :value="page.Texts[29].content_fr" v-model="form.functionality">
                                <label for="survey_form_functionality_1">
                                    {{page.Texts[29]['content_'+$i18n.locale]}}
                                </label>
                            </div>
                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="checkbox" id="survey_form_functionality_2"
                                       :value="page.Texts[30].content_fr" v-model="form.functionality">
                                <label for="survey_form_functionality_2">
                                    {{page.Texts[30]['content_'+$i18n.locale]}}
                                </label>
                            </div>
                            <div class="flex flex-row mt-5 items-start ml-7 lg:ml-16">
                                <input class="mr-5" type="checkbox" id="survey_form_functionality_3"
                                       :value="page.Texts[31].content_fr" v-model="form.functionality">
                                <label for="survey_form_functionality_3">
                                    {{page.Texts[31]['content_'+$i18n.locale]}}
                                </label>
                            </div>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <label for="survey_form_others_need" class="mt-5">
                            {{page.Texts[32]['content_'+$i18n.locale]}}
                        </label>
                        <input type="text" id="survey_form_others_need"  v-model="form.others_need"/>

                        <label for="project_form_document" id="files"  class="custom-file-upload background-purple uppercase font-bold px-7 py-2 mt-5 w-72 text-center">
                            {{page.Texts[33]['content_'+$i18n.locale]}}
                         </label>
                        <input accept=".pdf, .jpeg, .jpg, .png, .zip, .ppt, .pptx, .doc, .docx" type="file" id="project_form_document" @change="handleFileUpload()" ref="file" multiple>
                        <small class="text-white">
                            {{page.Texts[34]['content_'+$i18n.locale]}}
                        </small>
                        <ul class="mt-3 ">
                            <li class="flex" v-for="(file, index) in this.files" :key="index" >
                                <span class="mw-11/12 break-all text-white">{{file['name']}}</span>
                                <i title="Supprimer le fichier" @click="removeFile(files, index)" class="leading-6 text-purple fas fa-times ml-5"></i>
                            </li>
                        </ul>
                        <p v-if="fileErrors" class="invalid-feedback">{{fileErrors}}</p>
                        <LoadingButton bg="background-blue" custom-style="lg:w-auto lg:self-center" id="survey" :text-loading="page.Texts[38]['content_'+$i18n.locale]" :text-button="page.Texts[35]['content_'+$i18n.locale]"/>
                    </div>
                </section>
            </form>
        </ValidationObserver>

    </main>
</template>

<style lang="scss">
    #survey_form{
        input[type="text"], input[type="number"], input[type="date"] {
            border-bottom: 3px solid $beige;
        }
        label{
            color: $beige;
        }


    }
</style>

<script>
    import axios from 'axios';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import formMixin from '../mixins/formMixin'
    import renderMixin from "../mixins/renderMixin";
    import LoadingButton from "../components/LoadingButton";

    export default {
        components: {
            LoadingButton,
            ValidationProvider,
            ValidationObserver
        },
        mixins: [formMixin, renderMixin],
        data(){
            return{
                form: {
                    client_name: '',
                    email: '',
                    phone: '',
                    type: '',
                    website_name: '',
                    services: '',
                    pages_number: '',
                    budget:'',
                    start_date: '',
                    visual_identity: '',
                    competitors: '',
                    pages: [],
                    functionality: [],
                    others_need: '',
                    visual_identity_other:null
                },
                files: [],
                submitted: false,
                api_message: '',
                submitSuccess: false,
                isErrors: false,
                fileErrors: false,
                page:null
            }
        },
        mounted() {
            axios
                .get(process.env.VUE_APP_API_URL + '/api/pages/10')
                .then(response => (
                        this.page = response.data,
                        this.sendDataToParent(this.page)
                    )
                )
        },
        methods : {

            submitForm(){
                this.api_message='';
                this.submitSuccess=false;
                this.isErrors = false;
                this.fileErrors = false;

                if(this.files != null){
                    this.files.forEach(file => {
                        if(file.size >= 1000000){
                            this.fileErrors = true;
                        }
                    })
                }


                this.$refs.observer.validate().then(valid => {
                    if (valid && !this.fileErrors) {
                        this.changeButton('#submitBtn_survey', '#loadingBtn_survey')

                        var fd =this.setFormWithFiles(this.files, this.form)

                        axios.post(
                            process.env.VUE_APP_API_URL+'/api/survey',
                            fd,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        ).then(() => {
                            this.submitSuccess=true;
                        })
                            .catch((error) => {
                                this.api_message = "Une erreur est survenue ("+error+")",
                                 this.changeButton( '#loadingBtn_survey','#submitBtn_survey')
                            }).finally(() => {
                            //Perform action in always
                        });

                    } else{
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        this.isErrors = true;
                    }
                });
            },
            handleFileUpload(){
                this.fileErrors = false;
                this.$refs.file.files.forEach(file =>{
                    if(this.verifyFileSize(file) == true){
                        this.files.push(file)
                    } else{
                        this.fileErrors = "Un ou plusieurs fichiers n'ont pas été ajouté car il(s) dépasse(nt) 1 Mo"
                    }
                })
            },
        }
    }
</script>
