<template>
    <div class="space-y-1 text-black" id="container">
        <label id="listbox-label" class="hidden">
            <slot name="SelectLabel"></slot>
        </label>
        <div class="relative" v-click-outside="closeDropdown">
         <span class="inline-block w-full  shadow-sm">
            <button type="button" @click="!isOpen ? openDropdown() : closeDropdown()" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" class="cursor-pointer relative w-full  text-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
               <div class="flex items-center space-x-3">
                  <img :src="require('@/assets/icons/' + value.img + '')" alt="" class="flex-shrink-0 h-6 w-6" />
                  <span class="block truncate">
                  {{ value.title }}
                  </span>
               </div>
               <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 0 490.688 490.688" style="enable-background:new 0 0 490.688 490.688;" xml:space="preserve"
                       class="h-5 w-5 text-gray-400"  fill="none" stroke="currentColor">
                    <path style="fill:#faeee8;" d="M472.328,120.529L245.213,347.665L18.098,120.529c-4.237-4.093-10.99-3.975-15.083,0.262
                        c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667
                        c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262
                        L472.328,120.529z"/>
                    <path d="M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083
                        c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262
                        c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279
                        C250.748,372.281,248.039,373.408,245.213,373.415z"/>
                </svg>
               </span>
            </button>
         </span>
            <!-- Select popover, show/hide based on select state. -->
            <div v-show="isOpen" class="absolute mt-1 w-full  bg-white shadow-lg background-beige">
                <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-56  py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                    <!--
                       Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
                       Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
                       -->
                    <li tabindex="0" @click="select(d)"  role="option" v-for="(d, index) in data" :key="index"
                        class="listbox-item text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9  cursor-pointer hover:text-white focus:outline-none focus:text-white "
                        :class="isSelected(d) ? 'background-gold':''">
                        <div class="flex items-center space-x-3">
                            <img :src="require('@/assets/icons/' + d.img + '')" alt="" class="flex-shrink-0 h-6 w-6" />
                            <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                            <span class="block truncate text-purple" v-bind:class="{ 'font-normal' : !isSelected(d) , 'font-semibold' : isSelected(d)}">
                     {{ d.title }}
                     </span>
                        </div>
                        <!--
                           Checkmark, only display for selected option.
                           Highlighted: "text-white", Not Highlighted: "text-indigo-600"
                           -->
                        <span v-show="isSelected(d)" class="absolute inset-y-0 right-0 flex items-center pr-4">
                     <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                     </svg>
                  </span>
                    </li>
                    <!-- More options... -->
                </ul>
            </div>
        </div>
    </div>
</template>



<script>
    import ClickOutside from 'vue-click-outside'
    export default {
        name: 'VueSelect',
        props: {
            data: Array,
            value: Object
        },
        data() {
            return {
                'isOpen' : false
            }
        },
        methods: {
            isSelected(value) {
                return this.value === value;
            },
            closeDropdown() {
                this.isOpen = false;
            },
            openDropdown() {
                this.isOpen = true;
            },
            select(value) {
                this.isOpen = false;
                this.$emit('valueSelect', value);
            }
        },
        directives: {
            ClickOutside
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #container{
        width:7.25rem;
    }
    button{
        border:1px solid $beige;
    }
    .listbox-item{
        &:hover, &:focus{
            background-color: $gold;
                div{
                    span{
                        color:beige;
                    }
                }
        }

    }
</style>