/* eslint camelcase: "off" */
import { extend, setInteractionMode } from 'vee-validate';
import {
  required,
  email,
  numeric
} from 'vee-validate/dist/rules';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire',
});

extend('email', {
  ...email,
  message: 'Indiquez une adresse mail au bon format',
});


extend('url', {
  validate: (value) => {
    const regex = new RegExp(/^(http|ftp|https)?(:\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^!=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])+$/);
    return regex.test(value);
  },
  message: 'Veuillez remplir une url valide',
});

extend('numeric', {
  ...numeric ,
  message: 'Vous devez saisir un chiffre'
});
